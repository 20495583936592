import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Plan, UserSubscription } from '../../../../../shared/interfaces/Stripe';
import AlertModal, { EModalType } from '../Modals/AlertModal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Alert } from '@material-ui/lab';
import { getApolloError } from '../../../../../shared/utils/apolloError';
import { IStripeProduct } from '../../../../../shared/interfaces/StripeProduct';
import RoundSpinner from './Spinner';
import { IonEvent } from '../interface/onEvent.interface';
import { IUser } from '../../../../../shared/interfaces/User';
import GET_STRIPE_USER_SUBSCRIPTION from '../../../../../graphql/stripe/getStripeUserSubscription.query copy';
import CANCEL_STRIPE_SUBSCRIPTION from '../../../../../graphql/stripe/cancelStripeSubscription.mutation';
import GET_STRIPE_PRODUCT_SUBSCRIPTION from '../../../../../graphql/stripe/getStripeProductSubscription.query';
import REACTIVATE_STRIPE_SUBSCRIPTION from '../../../../../graphql/stripe/reactivateStripeSubscription.mutation';
import CHANGE_STRIPE_SUBSCRIPTION from '../../../../../graphql/stripe/changeStripeSubscription.mutation';
import { useHistory } from 'react-router-dom';
import { ROOT_ROUTES } from '../../../../../shared/constants/routes';

interface IProps {
  user: IUser;
  onEvent: (ev?: IonEvent) => void;
}

const MemberShip = (props: IProps) => {
  const { user, onEvent } = props;
  const history = useHistory();

  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [reactivateModal, setReactivateModal] = useState<boolean>(false);

  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
  const [isAnnual, setIsAnnual] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);

  const [userSubscription, setUserSubscription] = useState<UserSubscription>(null);
  const [stripeProducts, setStripeProducts] = useState<{ monthly: IStripeProduct; annual: IStripeProduct }>(null);
  const [savings, setSavings] = useState<number>(0);

  const [
    subscriptionInfo,
    { data: subData, error: subError, loading: subLoading },
  ] = useLazyQuery(GET_STRIPE_USER_SUBSCRIPTION, { fetchPolicy: 'network-only' });
  const { data: prodData, error: prodError, loading: prodLoading } = useQuery(GET_STRIPE_PRODUCT_SUBSCRIPTION);

  const [changeSubscription, { error: changeError, loading: changeLoading }] = useMutation(CHANGE_STRIPE_SUBSCRIPTION);
  const [cancelSubscription, { error: cancelError, loading: cancelLoading }] = useMutation(CANCEL_STRIPE_SUBSCRIPTION);
  const [reactivateSubscription, { error: reactivateError, loading: reactivateLoading }] = useMutation(
    REACTIVATE_STRIPE_SUBSCRIPTION,
  );

  const loading = subLoading || prodLoading || cancelLoading || reactivateLoading || changeLoading;
  const error = subError || prodError || reactivateError || cancelError || changeError;

  const ArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox='0 0 1024 1024' fill='currentColor' height='1em' width='1em' {...props}>
      <path d='M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z' />
    </svg>
  );

  const formatToDate = (timestamp: number) => {
    return timestamp === 0 ? '-' : moment(timestamp * 1000).format('dddd, MMMM Do YYYY');
  };

  const Paymentstatus = (data: UserSubscription) => {
    const { status } = data.subscription;
    const severity = status !== 'active' && status !== 'trialing' ? 'error' : 'success';

    if (data.schedule && data.schedule?.id && data.schedule?.start_date * 1000 > Date.now()) {
      const msg = `New subscription will start on ${formatToDate(data.schedule.start_date)}`;
      return <Alert severity={severity}>{msg}</Alert>;
    }

    return (
      <Alert
        severity={severity}
        className={`border ${status === 'active' || status === 'trialing' ? 'border-green-border' : null} `}
      >
        {status === 'trialing' ? 'This membership was gifted to you!' : status.toUpperCase()}
      </Alert>
    );
  };

  const formatString = (subs: UserSubscription) => {
    const { subscription, schedule } = subs;

    const temp = subscription.plan.amount_decimal;
    const interval = subscription.plan.interval;
    const discount = subscription.discount;
    const isCanceled = subscription.status === 'canceled';
    const isValidCoupon = discount && discount.coupon && discount.coupon.valid ? true : false;

    const formatCurrency = (temp: number | string) => {
      let data = typeof temp === 'number' ? temp.toString() : temp;
      return `${data.slice(0, data.length - 2)}.${data.slice(data.length - 2)}`;
    };

    return (
      <div className='flex flex-col items-start w-full gap-4'>
        <div>
          <span className={`text-2xl md:text-3xl font-bold ${discount ? 'line-through' : ''}`}>
            ${formatCurrency(temp)}
          </span>
          /{interval}
          {/* {discount && isValidCoupon && (
            <span className=' bg-gray-100 rounded p-2 font-semibold ml-4'>{discount.coupon.name} </span>
          )} */}
        </div>
        {isCanceled && <p>Your subscription has been canceled</p>}
        {discount && isValidCoupon && !isCanceled ? (
          <div className='w-full space-y-3'>
            <span className='text-sm font-semibold'> Promo Code used: </span>
            {discount.coupon.amount_off ? (
              <span className='text-sm font-semibold'>
                {' '}
                ${formatCurrency(discount?.coupon?.amount_off)} discount for {discount.coupon.duration_in_months}{' '}
                {interval}
              </span>
            ) : null}
            {discount.coupon.percent_off ? (
              <span className='text-sm font-semibold'>
                {discount?.coupon?.percent_off}% off for {discount.coupon.duration_in_months} {interval}
              </span>
            ) : null}
            {discount.code && <span className='text-xs'>, Finishes on {formatToDate(discount.end)} .</span>}
          </div>
        ) : null}
        {schedule && !isCanceled && (
          <>
            <div>
              <ArrowRight />
            </div>
            <div>
              <span className='text-2xl md:text-3xl font-bold'>
                ${schedule.price.unit_amount.slice(0, schedule.price.unit_amount.length - 2)}.
                {schedule.price.unit_amount.slice(schedule.price.unit_amount.length - 2)}
              </span>
              /{schedule.price.interval}
            </div>
          </>
        )}
      </div>
    );
  };

  const formatToPrice = (temp: string) => {
    try {
      return `${temp.slice(0, temp.length - 2)}.${temp.slice(temp.length - 2)}`;
    } catch (error) {
      return `NaN`;
    }
  };

  const onClose = (data: string) => {
    setPaymentModal(false);
    setCancelModal(false);
    setReactivateModal(false);
  };

  const onConfirm = (data: EModalType) => {
    setPaymentModal(false);
    setCancelModal(false);
    setReactivateModal(false);
    if (data === EModalType.UPDATE) {
      changeUserSubscription();
    } else if (data === EModalType.CANCEL) {
      cancelUserSubscription();
    } else if (data === EModalType.REACTIVATE) {
      onReactivateSubscription();
    }
  };

  const onReactivateSubscription = () => {
    reactivateSubscription().then(() => {
      onEvent({
        type: 'membership',
        data: {
          severity: 'success',
          message: `You've successfully reactivated your subscription. Keep living each day as the best version of yourself and co create your own life with the Co Create app!`,
        },
      });
    });
  };

  useEffect(() => {
    subscriptionInfo().then();
  }, []);

  useEffect(() => {
    if (subData) {
      const data = get(subData, 'getUserStripeSubscriptionInfo', null);
      setUserSubscription(data);
      setIsAnnual(data?.subscription.plan.interval === 'month' ? false : true);
    }
  }, [subData]);

  useEffect(() => {
    if (prodData) {
      let monthly = get(prodData, 'getStripeProductSubscription.monthly', null);
      let annual = get(prodData, 'getStripeProductSubscription.annual', null);
      const individualAmount = (monthly.price.unit_amount_decimal * 12) / 100;
      const toSave = Math.round(100 - annual.price.unit_amount_decimal / individualAmount);
      setStripeProducts({ monthly, annual });
      setSavings(toSave);
    }
  }, [prodData]);

  useEffect(() => {
    if (error) {
      setShowErrorMsg(true);
    }
  }, [error]);

  const changeUserSubscription = () => {
    changeSubscription({
      variables: {
        input: {
          priceId: stripeProducts[isAnnual ? 'monthly' : 'annual'].price.id,
          plan: isAnnual ? 'month' : 'year',
        },
      },
      refetchQueries: [{ query: GET_STRIPE_USER_SUBSCRIPTION }],
    }).then((data) => {
      if (!data.errors) {
        onEvent({
          type: 'membership',
          data: { severity: 'success', message: `You've successfully update your membership!` },
        });
      }
    });
  };

  const cancelUserSubscription = () => {
    cancelSubscription({ refetchQueries: [{ query: GET_STRIPE_PRODUCT_SUBSCRIPTION }] }).then(() =>
      onEvent({ type: 'membership', data: { severity: 'success', message: `You've successfully unsubscribed!` } }),
    );
  };

  const onSelectPlan = () => {
    setWaiting(true);
    setTimeout(() => {
      setWaiting(false);
      history.replace(`/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}/plans`);
    }, 800);
  };

  const handleExternalSubscriptionStatus = () => (
    <div className='pt-6'>
      <Alert severity='warning' className='border border-[#663c00]'>
        You are subscribed via In-App Purchases. Please use the mobile app to manage your subscription.
      </Alert>
    </div>
  );

  const handleSubscriptionStatus = () => {
    return !user.hasSubscription && user.stripeData.status === 'CANCELED' ? (
      <>
        <Alert severity='warning'>
          Subscription Canceled
          {user.stripeData.refund && user.stripeData.refund.refunded && (
            <span className='text-sm font-semibold'>. The subscription has been refunded</span>
          )}
        </Alert>
        <div className='pt-4'>
          <button
            disabled={loading}
            //new subscrition display plan page
            onClick={onSelectPlan}
            className='flex w-full justify-center rounded border border-grayBg py-3 
                px-4 text-sm font-medium text-black hover:bg-subscriptionBtn focus:outline-none focus:ring-2 
                focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
          >
            {waiting && (
              <span className='ml-1'>
                {' '}
                <RoundSpinner color={'text-black'} />{' '}
              </span>
            )}
            Purchase a Subscription
          </button>
        </div>
      </>
    ) : user.hasSubscription && user.unsubscribeUserEOM ? (
      <>
        <Alert severity='warning'>
          {userSubscription?.subscription?.status?.toUpperCase()} until{' '}
          {formatToDate(userSubscription?.subscription.current_period_end)}
        </Alert>
        <div className='pt-4'>
          <button
            disabled={loading}
            onClick={() => setReactivateModal(true)}
            className='flex w-full justify-center rounded border border-grayBg py-3 
        px-4 text-sm font-medium text-black hover:bg-subscriptionBtn focus:outline-none focus:ring-2 
        focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
          >
            Reactivate Subscription
            {reactivateLoading && (
              <span className='ml-1'>
                {' '}
                <RoundSpinner color={'text-black'} />{' '}
              </span>
            )}
          </button>
        </div>
      </>
    ) : (
      user.hasSubscription &&
      !user.unsubscribeUserEOM && (
        <>
          <div className='flex flex-col gap-2'>
            {!userSubscription && (
              <div className='text-center'>
                <RoundSpinner color={'text-black'} />
              </div>
            )}
            {userSubscription && (
              <>
                <div>{formatString(userSubscription)}</div>
                <div className='py-4'>{Paymentstatus(userSubscription)}</div>
                {!userSubscription?.schedule && userSubscription?.subscription.status !== 'canceled' && (
                  <div className='text-sm'>
                    Renews on {formatToDate(userSubscription.subscription.current_period_end)}
                  </div>
                )}
              </>
            )}
            {!userSubscription?.schedule && isAnnual && (
              <div className='pt-4'>
                <button
                  disabled={loading}
                  onClick={() => setPaymentModal(true)}
                  className='flex w-full justify-center rounded border border-grayBg py-3 
        px-4 text-sm font-medium text-black hover:bg-subscriptionBtn focus:outline-none focus:ring-2 
        focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
                >
                  Switch to Monthly
                  {changeLoading && (
                    <span className='ml-1'>
                      {' '}
                      <RoundSpinner color={'text-black'} />{' '}
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>
          <div
            className='text-[#f00] text-sm hover:underline hover:underline-offset-3 cursor-pointer flex items-center gap-3'
            onClick={() => setCancelModal(true)}
          >
            Cancel Subscription
            {cancelLoading && (
              <span className='ml-1'>
                {' '}
                <RoundSpinner color={'text-black'} />{' '}
              </span>
            )}
          </div>
        </>
      )
    );
  };

  return (
    <>
      <AlertModal
        type={EModalType.UPDATE}
        title={`Switch to ${isAnnual ? 'Monthly' : 'Annual'} Billing?`}
        body={`Your membership will switch from ${!isAnnual ? 'Monthly' : 'Annual'} to ${
          isAnnual ? 'Monthly' : 'Annual'
        }, and you will be charged $ ${
          isAnnual
            ? formatToPrice(stripeProducts?.monthly?.price.unit_amount_decimal)
            : formatToPrice(stripeProducts?.annual?.price.unit_amount_decimal)
        } on ${formatToDate(
          userSubscription?.subscription.current_period_end,
        )}. Are you sure you want to switch to annual billing?`}
        cancelText={`Stay on ${!isAnnual ? 'Monthly' : 'Annual'}`}
        okText={`Yes, Switch to ${isAnnual ? 'Monthly' : 'Annual'}`}
        open={paymentModal}
        onCloseModal={onClose}
        onConfirm={(e) => onConfirm(e)}
      />
      <AlertModal
        type={EModalType.CANCEL}
        title='Cancel Subscription'
        body={`
        Are you sure you want to cancel your Co Create membership? After ${formatToDate(
          userSubscription?.subscription.current_period_end,
        )}, you will only have acess to Co Create's free content.`}
        cancelText={`Keep My Membership`}
        okText={`Yes, Cancel`}
        open={cancelModal}
        onCloseModal={onClose}
        onConfirm={(e) => onConfirm(e)}
      />
      <AlertModal
        type={EModalType.REACTIVATE}
        title='Reactivate Subscription'
        body={`Are you sure you want to reactivate your subscription? You will continue to be billed as usual.`}
        cancelText={`Stay The Same`}
        okText={`Yes, Reactivate`}
        open={reactivateModal}
        onCloseModal={onClose}
        onConfirm={(e) => onConfirm(e)}
      />
      <div className='md:w-2/5 px-4 md:px-8 pb-6 space-y-6'>
        <h1 className='font-bold text-2xl pt-10'>Current Membership</h1>

        {user.appUserId ? handleExternalSubscriptionStatus() : handleSubscriptionStatus()}

        {showErrorMsg && !user.appUserId && (
          <div className='pt-2'>
            <Alert onClose={() => setShowErrorMsg(false)} severity='error'>
              {getApolloError(error)}
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default MemberShip;
